<template>
  <div id="login-email">
    <AppContainer :use-background="true">
      <HeaderCommon :use-logo="true" ref="header">
        <template v-slot:headerButton>
          <ButtonBack class="btn-back position-absolute" />
        </template>
      </HeaderCommon>
      <div id="content-scroll" class="content-scroll">
        <div class="login-email">
          <img
            class="logo"
            alt="logo-login-email"
            src="@/assets/image/logo.svg"
          />
          <div class="login-email__content">
            <div
              v-if="urlRedirectFacebook && urlRedirectLine"
              class="login-facebook"
            >
              <button
                @click.prevent="redirectUrlLine"
                class="login-line f-w3  d-flex justify-content-center align-items-center"
              >
                <img
                  src="@/assets/image/line.svg"
                  class="login-line-logo"
                  alt="logo-login-line"
                />
                <div><span>LINEでログイン</span></div>
              </button>
              <button
                @click.prevent="redirectUrlFacebook"
                class="login-facebook f-w3 d-flex justify-content-center align-items-center"
              >
                <img
                  src="@/assets/image/facebook.svg"
                  class="login-facebook-logo"
                  alt="logo-login-facebook"
                />
                <div><span>Facebookでログイン</span></div>
              </button>
            </div>
            <p class="link-register f-w3">メールアドレスでログイン</p>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form class="form" @submit.prevent="handleSubmit(login)">
                <ValidationProvider
                  name="メールアドレス"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <label for="email" class="f-w6">メールアドレス</label>
                  <input
                    class="f-w3"
                    v-model="email"
                    type="text"
                    id="email"
                    name="email"
                    @blur="handleBlur()"
                  />
                  <span class="error text-left f-w3">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider
                  name="パスワード"
                  rules="required|min:6|max:25"
                  v-slot="{ errors }"
                >
                  <label for="password" class="f-w6">パスワード</label>
                  <input
                    class="f-w3"
                    v-model="password"
                    type="password"
                    id="password"
                    name="password"
                  />
                  <span class="error text-left f-w3">{{ errors[0] }}</span>
                </ValidationProvider>
                <input type="submit" class="btn-login f-w3" value="ログイン" />
              </form>
            </ValidationObserver>
            <router-link
              :to="{ name: 'TopRegister' }"
              class="link-register f-w3"
            >
              アカウントをお持ちでない方はこちら
            </router-link>
            <router-link
              :to="{ name: 'ResetPasswordByEmail' }"
              class="link-forgot-password f-w3"
            >
              パスワードを忘れた場合はこちら
            </router-link>
            <!-- <div class="horizontal-line">
              <div />
              <span class="f-w3">OR</span>
              <div />
            </div> -->
          </div>
        </div>
      </div>
    </AppContainer>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import HeaderCommon from "@/views/Common/HeaderCommon";
import ButtonBack from "@/views/Common/ButtonBack";
import Cookies from "js-cookie";
import firebase from "firebase";
import { GENERAL_PASSWORD } from "@/constants/message";

export default {
  name: "LoginEmail",
  components: {
    HeaderCommon,
    ButtonBack
  },
  data() {
    return {
      email: null,
      password: null
    };
  },
  computed: {
    ...mapGetters({
      urlRedirectFacebook: "auth/urlRedirectFacebook",
      urlRedirectLine: "auth/urlRedirectLine"
    })
  },
  created() {
    if (!this.$route.query.code) {
      if (Cookies.get("invitation_code")) {
        Cookies.remove("invitation_code");
      }
    }
    this.$store.dispatch("auth/getUrlRedirectFacebook");
    this.$store.dispatch("auth/getUrlRedirectLine", {
      sex: null,
      type: 1
    });
  },
  methods: {
    handleBlur() {
      this.email = this.email.trim();
    },
    async login() {
      this.$root.$refs.loading.start();
      await this.$store
        .dispatch("auth/loginUser", {
          email: this.email,
          password: this.password
        })
        .then(async () => {
          this.$root.$refs.loading.finish();
          this.$toast("正常にログインしました", "通知", "success");
          await this.$store.dispatch("auth/getInfoUser");
          const userGender = this.$store.getters["auth/user"];
          const md5 = require("md5");
          const data = {
            email: userGender.email,
            password: md5(GENERAL_PASSWORD)
          };
          firebase.auth().signInWithEmailAndPassword(data.email, data.password);
          localStorage.removeItem("add-friend-line");
          if (userGender.registration_status == 1) {
            localStorage.removeItem("step");
            localStorage.setItem("step", 1);
            this.$router.push({ name: "RegisterProfile" });
          }
          if (userGender.sex == 1) {
            localStorage.removeItem("step");
            this.$router.push({
              name: "Home",
              params: {
                showPopup: "show"
              }
            });
          } else {
            localStorage.removeItem("step");
            this.$router.push({
              name: "CastHome",
              params: {
                showPopup: "show"
              }
            });
          }
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
          this.$toast(
            "アカウントまたはパスワードが正しくありません",
            "通知",
            "danger"
          );
        });
    },
    redirectUrlFacebook() {
      localStorage.removeItem("step");
      localStorage.removeItem("sex");
      localStorage.setItem("type", 1);
      const urlRedirect = this.urlRedirectFacebook;
      if (urlRedirect !== null) {
        window.location = urlRedirect;
      }
    },
    redirectUrlLine() {
      localStorage.removeItem("step");
      localStorage.removeItem("sex");
      localStorage.setItem("type", 1);
      const urlRedirect = this.urlRedirectLine;
      if (urlRedirect !== null) {
        window.location = urlRedirect;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/loginEmail.scss";
@import "~@/assets/scss/_fontFamily.scss";
@import "~@/assets/scss/_commons.scss";
#login-email {
  /deep/ .app-body._header .app-container__content {
    background-color: #f5f5f5;
    height: calc(100vh);
  }
}
.content-scroll {
  // overflow-y: scroll;
  padding-top: 42px;
  padding-bottom: 30px;
  overflow-x: hidden;
  // height: calc(100vh);
}

@media screen and (min-width: 1200px) {
  // .content-scroll {
  //   height: calc(100vh - 61px);
  // }
}

// #login-email {
//   /deep/ .app-container {
//     &__content {
//       height: 100vh;
//     }
//   }
// }
</style>
